define("simtex-myaccount/models/musiconhold", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    musiconholdid: _emberData.default.attr(),
    owner: _emberData.default.attr(),
    label: _emberData.default.attr('string'),
    directory: _emberData.default.attr('string'),
    applications: _emberData.default.attr('string'),
    mode: _emberData.default.attr('string'),
    digit: _emberData.default.attr('string'),
    sort: _emberData.default.attr('string'),
    format: _emberData.default.attr('string')
  });
  _exports.default = _default;
});