define("simtex-myaccount/models/account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    owner: _emberData.default.attr(),
    signupdate: _emberData.default.attr('date'),
    label: _emberData.default.attr('string'),
    internalextension: _emberData.default.attr(),
    password: _emberData.default.attr(),
    accountid: _emberData.default.attr(),
    didrouteid: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    smilesubscriptionid: _emberData.default.attr(),
    migrated: _emberData.default.attr('boolean'),
    musiconholdid: _emberData.default.attr(),
    musiconhold_label: _emberData.default.attr('string'),
    channellimit_data: _emberData.default.belongsTo('channellimit'),
    dialrestriction_data: _emberData.default.belongsTo('dialrestriction'),
    productid: _emberData.default.attr(),
    product_data: _emberData.default.belongsTo('product'),
    product_data_label: _emberData.default.attr('string'),
    // didroute_data: DS.belongsTo('didroute'),
    voicemail_data: _emberData.default.belongsTo('voicemail'),
    transport_data: _emberData.default.belongsTo('transport'),
    numberpresentation_data: _emberData.default.belongsTo('numberpresentation'),
    callaccounting_data: _emberData.default.belongsTo('callaccounting'),
    musiconhold_data: _emberData.default.hasMany('musiconhold'),
    did_data: _emberData.default.hasMany('did', {
      async: false
    }),
    endpointaccount_data: _emberData.default.hasMany('endpointaccount', {
      async: false
    }),
    callcontrol_data: _emberData.default.hasMany('callcontrol'),
    advancedforwardcontrol_data: _emberData.default.hasMany('advancedforwardcontrol')
  });
  _exports.default = _default;
});